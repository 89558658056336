<template>
   <div class="">
       <div class="form-data file">
           <div class="title">첨부</div>
           <div class="value">
               <el-upload
                       class="file_btn"
                       action=""
                       :auto-upload="false"
                       :limit="3"
                       :file-list="files"
                       :on-change="handleFileChange"
                       :on-remove="handleFileRemove"
                       :on-exceed="handleFileExceed"
               >
                   <el-button size="small" type="primary" >파일추가</el-button>
                   <div slot="tip" class="noti el-upload__tip">(10mb이하, 이미지파일 최대 3개 가능)</div>
               </el-upload>
           </div>
       </div>
   </div>
</template>

<script>
    import EventBus from "@/utils/event-bus";
    import elFileMixins from "@/mixins/elFileMixins";
    import BoardValidator from "@/mixins/validators/BoardValidator";
    import util from "@/mixins/util";

    export default {
        name: "BoardWriteFileLayout",
        mixins: [elFileMixins, BoardValidator],
        // inject: ['boardWriteSetData'],
        inject: {
            boardWriteSetData: { default: () => {} }
        },
        props: {
            boardData: {
                default: {}
            },
        },
        data() {
            return {
                imageMaxSize : 1024 * 1024 * 10,
                fileExceedText: '파일은 세 개까지 가능합니다.',
            }
        },
        computed: {},
        mounted() {
            this.initData();
        },
        methods: {
            initData() {
                if(!util.isEmpty(this.boardData) && this.boardData.hasOwnProperty('Files')) {
                    this.files = this.boardData.Files.filter(file => file.a_idx != null);
                    this.files.forEach(file => {
                        file.name = file.org_name;
                    })
                    this.parentSetData('files', this.files);
                }
            },
            parentSetData(type, value) {
                this.boardWriteSetData(type, value);
            },

        },
    }
</script>

<style scoped>

</style>